import { formatClockDuration } from "~/routes/users.$accountId.($matchMode)/components/shared/formatClockDuration";
import { getTiersFromRank, rankTitles, romanNumerals } from "~/shared/helpers/ranks/ranks";
import { DateTime } from "luxon";
import { Link } from "@remix-run/react";
import { generateRankImage } from "~/shared/helpers/generateRankImage";
import { formatThousands } from "~/shared/helpers/formatThousands";

type StatToShow = "matchId" | "duration" | "averageRank" | "souls" | "kills";
interface MatchStatCardProps {
  title: string;
  matchId: string;
  duration: number;
  startTime: string;
  statsToShow: StatToShow[];
  statToHighlight: StatToShow;
  souls: {
    teamA: number;
    teamB: number;
  };
  averageRank: number;
  kills: number;
}

function renderStat(stat: StatToShow, data: MatchStatCardProps) {
  const { tier, subTier } = getTiersFromRank(data.averageRank);
  const rankUrl = generateRankImage({
    tier,
  });
  switch (stat) {
    case "matchId":
      return (
        <div className="flex h-4 items-center gap-1">
          <span className="text-neutral">Match ID</span>
          <span>{data.matchId}</span>
        </div>
      );
    case "duration":
      return (
        <div className="flex h-4 items-center gap-1">
          <span className="text-neutral">Duration</span>
          <span>{formatClockDuration({ seconds: data.duration })}</span>
        </div>
      );
    case "souls":
      return (
        <div className="flex h-4 items-center gap-1">
          <span className="text-neutral">Souls</span>
          <div className="flex items-center gap-0.5">
            <span className="text-deadlock-khaki">{formatThousands(data.souls.teamA)}</span>
            <span className="text-neutral">:</span>
            <span className="text-deadlock-cornflower">{formatThousands(data.souls.teamB)}</span>
          </div>
        </div>
      );
    case "averageRank":
      return (
        <div className="flex h-4 place-content-center items-center gap-1.5">
          <div className="text-neutral">Avg. Rank</div>
          <div className="flex items-center gap-1">
            <img src={rankUrl} className="h-4 shrink-0" alt="rank" />
            <span>{rankTitles[tier]}</span>
            <span>{romanNumerals[subTier]}</span>
          </div>
        </div>
      );
    case "kills":
      return (
        <div className="flex h-4 items-center gap-1">
          <span className="text-neutral">Kills</span>
          <span>{data.kills}</span>
        </div>
      );
    default:
      return null;
  }
}

function renderHighlightedStat(stat: StatToShow, data: MatchStatCardProps) {
  const { tier, subTier } = getTiersFromRank(data.averageRank);
  const rankUrl = generateRankImage({
    tier,
  });
  const subRankUrl = generateRankImage({
    tier,
    subTier,
  });

  switch (stat) {
    case "duration":
      return (
        <div className="flex items-center gap-1">
          <div>
            {formatClockDuration({
              seconds: data.duration,
            })}
          </div>
          <div className="text-neutral">Duration</div>
        </div>
      );
    case "averageRank":
      return (
        <div className="flex place-content-center items-center gap-1.5">
          <img src={rankUrl} className="h-6 shrink-0" alt="rank" />
          <img src={subRankUrl} className="h-6 shrink-0" alt="sub rank" />
          <div className="font-style-body-b3">Avg. Rank</div>
        </div>
      );
    case "kills":
      return (
        <div className="flex items-center gap-1">
          <div>{data.kills}</div>
          <div className="text-neutral">Kills</div>
        </div>
      );
    case "souls":
      return (
        <div className="flex items-center gap-1">
          <div className="flex items-center gap-0.5">
            <div className="text-deadlock-khaki">{formatThousands(data.souls.teamA)}</div>
            <div className="text-neutral">:</div>
            <div className="text-deadlock-cornflower">{formatThousands(data.souls.teamB)}</div>
          </div>
          <div className="text-neutral">Souls</div>
        </div>
      );
    default:
      return null;
  }
}

export function MatchStatCard(props: MatchStatCardProps) {
  return (
    <Link
      to={`/matches/${props.matchId}`}
      className="flex shrink grow flex-col place-content-start justify-between gap-2 rounded bg-background-95/70 px-3 py-2 antialiased transition-all duration-default ease-in-out hover:bg-background-90 md:h-40 md:min-w-64"
    >
      <div className="flex flex-col gap-2">
        <h3 className="line-clamp-1 font-style-heading-h5 md:font-style-heading-h3">{props.title}</h3>
        <div className="space-y-1">
          {props.statsToShow.map((stat) => (
            <div key={stat} className="flex w-full items-center justify-between font-style-body-b3">
              {renderStat(stat, props)}
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full items-center">
        <span className="font-style-heading-h3">{renderHighlightedStat(props.statToHighlight, props)}</span>
      </div>

      <div className="flex w-full items-center justify-start gap-1">
        <span className="text-neutral font-style-heading-h5 md:font-style-body-b3">
          {DateTime.fromISO(props.startTime).toRelative({ locale: "en-US" })}
        </span>
      </div>
    </Link>
  );
}
