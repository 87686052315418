import { MatchStatCard } from "./components/MatchStatCard";
import { ClosestMatch, HighestRankedMatch, LongestMatch, MostKillsMatch } from "@portal-frontend-ssr/blast-api";

interface MatchStatsCardsProps {
  highestRanked: HighestRankedMatch | null;
  longest: LongestMatch | null;
  mostKills: MostKillsMatch | null;
  closest: ClosestMatch | null;
}
export function MatchStatCards({ highestRanked, longest, mostKills, closest }: MatchStatsCardsProps) {
  return (
    <div className="grid w-full grid-cols-2 grid-rows-2 gap-2 lg:grid-cols-4 lg:grid-rows-1">
      {/* Highest Ranked Match */}
      {highestRanked && (
        <MatchStatCard
          title="Highest Ranked Match"
          matchId={highestRanked.matchId}
          startTime={highestRanked.startTime}
          statToHighlight="averageRank"
          averageRank={Math.floor(highestRanked.averageMatchBadge)}
          duration={highestRanked.durationS}
          kills={0}
          souls={{
            teamA: highestRanked.team0NetWorth,
            teamB: highestRanked.team1NetWorth,
          }}
          statsToShow={["matchId", "duration", "souls"]}
        />
      )}
      {/* Longest Match */}
      {longest && (
        <MatchStatCard
          title="Longest Match"
          matchId={longest.matchId}
          startTime={longest.startTime}
          statToHighlight="duration"
          averageRank={Math.floor(longest.averageMatchBadge)}
          duration={longest.durationS}
          kills={0}
          souls={{
            teamA: longest.team0NetWorth,
            teamB: longest.team1NetWorth,
          }}
          statsToShow={["matchId", "averageRank", "souls"]}
        />
      )}
      {/* Most Kills Match */}
      {mostKills && (
        <MatchStatCard
          title="Most Kills"
          matchId={mostKills.matchId}
          startTime={mostKills.startTime}
          statToHighlight="kills"
          averageRank={Math.floor(mostKills.averageMatchBadge)}
          duration={mostKills.durationS}
          kills={mostKills.totalKills}
          souls={{
            teamA: mostKills.team0NetWorth,
            teamB: mostKills.team1NetWorth,
          }}
          statsToShow={["matchId", "averageRank", "souls"]}
        />
      )}
      {/* Closest Match */}
      {closest && (
        <MatchStatCard
          title="Closest Match"
          matchId={closest.matchId}
          startTime={closest.startTime}
          statToHighlight="souls"
          averageRank={Math.floor(closest.averageMatchBadge)}
          duration={closest.durationS}
          kills={0}
          souls={{
            teamA: closest.team0NetWorth,
            teamB: closest.team1NetWorth,
          }}
          statsToShow={["matchId", "averageRank", "duration"]}
        />
      )}
    </div>
  );
}
